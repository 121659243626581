import { createSlice } from '@reduxjs/toolkit';
import { notificationsAction } from './notifications.action';
var moment = require('moment-timezone');
moment.tz.setDefault("America/New_York");
export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}

// ------ Project Overview Reducer for managing Project overview state. ------
const notificationsSlice = createSlice({
  name: "projects",
  initialState: {
    notificationsList: [],
    error: "",
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       // -------- cases of projects Timeline api data -------
       .addCase(notificationsAction.getNotificationsAction.pending, (state, action) => {
            state.error = "";
            state.notificationsList = [];
            state.loading = true;
      })
      .addCase(notificationsAction.getNotificationsAction.fulfilled, (state, action) => {
        if(action?.payload?.data?.status === 200) {
          state.notificationsList= action.payload.data.data;
          state.error = "";
        } else {
          state.notificationsList = [];
          state.error = "Unable to fetch notifications"
        }
      })
      .addCase(notificationsAction.getNotificationsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.notificationsList = [];
        state.error = action?.payload?.data?.message || "Unable to fetch notifications";
      })
  },
  
});

export default notificationsSlice.reducer;
