import http from "./http";

export const notificationsService = {
  notificationsListService,
  readNotificationService
};

// Notificationss Service
function notificationsListService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/notification/${id}/list`, );
}

// Notifications read Service
function readNotificationService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/notification/${payload?.id}/${payload?.notificationId}`, );
}


 

