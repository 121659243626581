// Auth
export const LOGIN = "/login"; //login
export const REGISTER = "/register/:token"; //register
export const FORGOT_PASSWORD = "/forgot-password"; //forgot password

// terms and conditions
export const TERMS_OF_SERVICES = "/terms-of-services"; //terms and conditions

// Documents
export const DOCUMENTS = "/documents/overview"; // document overview
export const FOLDERFILES = "/documents/:folderName/"; //folder files when user click on a folder
// under maintenance
export const UNDER_MAINTENANCE = "/under-maintenance"; 
// privacy
export const PRIVACY_POLACY ="/privacy-policy";
export const PAGE_NOT_FOUNT ="/404";
//MATERIAL_SELECTION
export const MATERIAL_SELECTION ="/material-selection";
export const GALLERY ="/gallery";
export const MESSAGELIST ="/messageList";
export const MESSAGETHREAD ="/messages/:id";

export const FINANCE ="/finance";
export const FINANCE_PROPOSAL_DETAILS ="/finance/proposal";

export const ACCOUNT_PREFERENCES = "/account-preferences";

export const LIVE_VIEW = "/live-view"
