import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsService } from '../../services/notifications.service';
import { showHideLoader } from '../common/common.slice';

// Async Thunk Actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// Notifications Listing Action
const getNotificationsAction = createAsyncThunk(
  'projectsSlice/getNotificationsAction', (id: string, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return notificationsService.notificationsListService(id)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
);

// Read Notification Action
const readNotificationAction = createAsyncThunk(
  'projectsSlice/readNotificationAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return notificationsService.readNotificationService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
);

export const notificationsAction = {
  getNotificationsAction,
  readNotificationAction
}

